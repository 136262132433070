import React from "react";
import { useNavigate } from "react-router-dom";

export default function Headerv4() {
  const navigate = useNavigate();

  return (
    <div className="border-t border-gray-200 w-full py-3 px-4 flex flex-col gap-6 tablet:flex-row tablet:gap-0 tablet:justify-between tablet:px-14 mac:px-20 bg-white relative z-50">
      <div className="w-full h-full px-1">
        <div className="w-full h-full flex justify-start">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src="/logo_v2.svg"
              className="object-contain h-10 w-auto"
              alt="explorationid logo"
            />
          </button>
        </div>
      </div>
    </div>
  );
}