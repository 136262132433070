import React, { useState, useEffect } from "react";
import FormField from "../components/FormField";
import Headerv4 from "../components/Header_v4";
import Footer from "../components/Footer";
import { useParams, useNavigate } from 'react-router-dom';
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import { useRecoilValue } from "recoil";


export default function FormReservasi() {
    const [emailError, setEmailError] = useState("");
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [phoneError, setPhoneError] = useState("");
    const auth = useRecoilValue(authState);
    const _api = auth.isAuth ? api : apiAnom;

    const { code } = useParams();
    const [reservationData, setReservationData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [eduLevels, setEduLevels] = useState([]);

    useEffect(() => {
      const validateReservation = async () => {
        try {
          const response = await apiAnom.post('/program-registration/validate', {
            code: code
          });
  
          if (response.data.code === 200) {
            setReservationData(response.data.data);
            setFormData(prevData => ({
              ...prevData,
              firstName: response.data.data.customer_first_name,
              lastName: response.data.data.customer_last_name,
              firstNamePeserta: response.data.data.participant_first_name,
              lastNamePeserta: response.data.data.participant_last_name,
            }));
          } else if (response.data.code === 406) {
            navigate('/form-reservasi-submitted');
          } else if (response.data.code === 410) {
            navigate('/form-reservasi-expired');
          } else if (response.data.code === 400) {
            navigate('/page-not-found');
          }
          } catch (error) {
            if (error.response?.data?.code === 406) {
              navigate('/form-reservasi-submitted');
            } else if (error.response?.data?.code === 410) {
              navigate('/form-reservasi-expired');
            } else if (error.response?.data?.code === 400) {
              navigate('/page-not-found');
            }        
          }
      };
  
      validateReservation();
    }, [code, navigate]);

    const handleSubmit = async () => {
      try {
        setIsSubmitting(true);
        const submitData = {
          code: code,
          educations_level_id: eduLevels.find(level => level.name === formData.grade)?.id,
          customer_email: formData.email,
          customer_first_name: formData.firstName,
          customer_last_name: formData.lastName,
          customer_phone: formData.phone,
          is_agree_doc: formData.documentConsent === 'setuju',
          is_participant: formData.selfRegistration === 'ya',
          participant_first_name: formData.selfRegistration === 'ya' ? formData.firstName : formData.firstNamePeserta,
          participant_last_name: formData.selfRegistration === 'ya' ? formData.lastName : formData.lastNamePeserta,
          participant_email: formData.selfRegistration === 'ya' ? formData.email : formData.emailPeserta,
          participant_phone: formData.selfRegistration === 'ya' ? formData.phone : formData.phonePeserta,
          participant_gender: formData.gender === 'L' ? 'Laki-laki' : 'Perempuan',
          participant_dob: formData.birthDate,
          participant_previous_school: formData.school,
          participant_major: formData.major || '', // optional
          topic_id: reservationData.topic_id
        };
    
        const response = await apiAnom.post('program-registration/submit', submitData);
    
        if (response.data.code === 201) {
          setTimeout(() => {
            setStep(7);
            setIsSubmitting(false); // Re-enable button
          }, 1000);
        }
      } catch (error) {
        setIsSubmitting(false);
        window.Swal.fire({
          title: "Error!",
          text: error.response?.data?.error?.message || "Something went wrong!",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      }
    };

    // get edu level
    useEffect(() => {
      _api.get('/program-registration/edu-level')
        .then((response) => {
          setEduLevels(response.data.data);
        })
        .catch((error) => {
          window.Swal.fire({
            title: "Error!",
            text: error.response?.data?.error?.message || "Something went wrong!",
            icon: "error",
            confirmButtonText: "Tutup",
          });
        });
    }, [_api]);

    // Initialize step from sessionStorage or default to 1
    const [step, setStep] = useState(() => {
      const savedStep = sessionStorage.getItem('formReservationStep');
      return savedStep ? parseInt(savedStep) : 1;
    });

    // Initialize formData from sessionStorage or empty object
    const [formData, setFormData] = useState(() => {
      const savedData = sessionStorage.getItem('formReservationData');
      return savedData ? JSON.parse(savedData) : {};
    });

    // Save step changes to sessionStorage
    useEffect(() => {
      sessionStorage.setItem('formReservationStep', step);
    }, [step]);

    // Save formData changes to sessionStorage
    useEffect(() => {
      sessionStorage.setItem('formReservationData', JSON.stringify(formData));
    }, [formData]);
  
  const validateStep = (currentStep) => {
    switch(currentStep) {
      case 2:
        return formData.firstName && 
               formData.lastName && 
               formData.email && 
               formData.phone &&
               !emailError &&
               !phoneError;
      case 3:
        return formData.documentConsent;
      case 4:
        return formData.selfRegistration;
      case 5:
        if (formData.selfRegistration === 'tidak') {
          return formData.firstNamePeserta && 
                 formData.lastNamePeserta && 
                 formData.emailPeserta && 
                 formData.phonePeserta && 
                 formData.gender && 
                 formData.birthDate && 
                 formData.school && 
                 formData.grade && 
                 (formData.grade?.includes('Semester') ? formData.major : true) &&
                 !emailError &&
                 !phoneError;
        }
        return formData.gender && 
             formData.birthDate && 
             formData.school && 
             formData.grade && 
             (formData.grade?.includes('Semester') ? formData.major : true);
      default:
        return true;
    }
  };

  // handle email peserta validation
  const handleEmailPesertaChange = (e) => {
    const email = e.target.value;
    setFormData({...formData, emailPeserta: email}); // Set the value first
  
    if (!validateEmail(email) && email !== "") {
      setEmailError("Format email tidak valid");
    } else {
      apiAnom.post('program-registration/check-email-participant', {
        email: email,
        topic_id: reservationData.topic_id
      })
      .then(response => {
        if (response.data.data.is_email_exist) {
          setEmailError("Peserta telah terdaftar, silahkan daftarkan peserta yang lain");
        } else {
          setEmailError("");
        }
      })
      .catch(() => {
        setEmailError("");
      });
    }
  };

  const steps = [
    { number: "01", title: "Data Nasabah" },
    { 
      number: "02",
      title: <div className="flex flex-col">
               <span>Pengembalian Materi</span>
               <span>Dokumentasi Peserta</span>
             </div> 
    },
    { number: "03", title: "Konfirmasi Data Peserta" },
    { number: "04", title: "Data Peserta" }
  ];

 const renderHero = () => (
    <div 
      className="w-full py-6"
      style={{
        backgroundImage: screenWidth < 1024 
        ? "url('/background_hero_form_reservasi_mobile.svg')"
        : "url('/background_hero_form_reservasi.svg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >
    </div>
  );

  const renderProgressBar = () => (
    <div className="px-20">
      <div className="flex items-center w-full my-8">
        {steps.map((item, index) => (
          <React.Fragment key={item.number}>
            <div className="flex items-center">
              <div className="flex items-center gap-2">
                <div
                  className={`w-10 h-10 rounded-full font-bold flex items-center justify-center ${
                    step > index + 1 ? "bg-yellow-300 text-black" : "text-white bg-gray-400"
                  }`}
                >
                  {item.number}
                </div>
                <div 
                    className="text-sm font-bold whitespace-nowrap"
                    style={{
                      fontSize: "16px",
                    }}
                >
                  <p>
                    {item.title}
                  </p>
                </div>
              </div>
            </div>
            {index < steps.length - 1 && (
              <div className="flex-1 mx-4">
                <div className="h-1 bg-gray-300">
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="h-0.5 bg-gray-200">
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData({...formData, email: email});
    
    if (!validateEmail(email) && email !== "") {
      setEmailError("Format email tidak valid");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    
    if (!validatePhone(phone) && phone !== "") {
      setPhoneError("Phone number can only contain numbers");
      return;
    }
    
    setPhoneError("");
    setFormData({...formData, phone: phone});
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <>
            {screenWidth < 1024 ? (
              // Mobile version
              <div className="flex flex-col gap-6 px-4 pt-4 pb-64">
                <h2 className="font-bold text-left" style={{ fontSize: "20px" }}>
                  Form Reservasi Nasabah<br/>
                  <span style={{ color: "#652E98" }}>
                    OCBC Summer Camp 2025
                  </span>
                </h2>

                <hr className="border-gray-400" />
        
                <div className="flex flex-col gap-6 text-sm" style={{ fontSize: "16px" }}>
                  {reservationData && (
                    <>
                      <div className="flex flex-col gap-2">
                        <p className="font-semibold">Nama Nasabah</p>
                        <p className="font-normal">{`${reservationData.customer_first_name} ${reservationData.customer_last_name}`}</p>
                      </div>

                      <div className="flex flex-col gap-2">
                        <p className="font-semibold">Nama Peserta</p>
                        <p className="font-normal">{`${reservationData.participant_first_name} ${reservationData.participant_last_name}`}</p>
                      </div>

                      <div className="flex flex-col gap-2">
                        <p className="font-semibold">Topik</p>
                        <p className="font-normal">{reservationData.topic.topic}</p>
                      </div>
                    </>
                  )}
                  <div className="flex justify-between mt-4">
                    {step > 1 && (
                      <button
                        onClick={() => setStep(step - 1)}
                        className="px-4 py-2 bg-gray-200 rounded-lg text-sm"
                      >
                        Sebelumnya
                      </button>
                    )}
                    <button
                      onClick={() => setStep(step + 1)}
                      className="px-10 py-2.5 bg-primaryColor text-white font-semibold rounded-lg text-sm ml-auto"
                      style={{ fontSize: "16px" }}
                    >
                      Mulai
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // Desktop version
              <div className="flex flex-col gap-10 pt-8 pl-96 pr-72 mx-auto">
                <h2 className="text-2xl font-bold text-center">
                  Form Reservasi <br/>
                  <span style={{ color: "#652E98" }}>
                    OCBC Summer Camp 2025
                  </span>
                </h2>
                
                <div className="flex flex-col gap-10" style={{ fontSize: "16px" }}>
                  {reservationData && (
                    <>
                      <div className="flex flex-row gap-10">
                        <p className="w-44 font-semibold">Nama Nasabah</p>
                        <p className="font-normal">{`${reservationData.customer_first_name} ${reservationData.customer_last_name}`}</p>
                      </div>

                      <div className="flex flex-row gap-10">
                        <p className="w-44 font-semibold">Nama Peserta</p>
                        <p className="font-normal">{`${reservationData.participant_first_name} ${reservationData.participant_last_name}`}</p>
                      </div>

                      <div className="flex flex-row gap-10">
                        <p className="w-44 font-semibold">Topik</p>
                        <p className="font-normal">{reservationData.topic.topic}</p>
                      </div>
                    </>
                  )}
                </div>
        
                <div className="flex justify-between pt-6 pb-18">
                  {step < 5 ? (
                    <button
                      onClick={() => setStep(step + 1)}
                      className="px-13 py-3.5 bg-primaryColor text-white font-bold rounded-lg ml-auto"
                    >
                      Mulai
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="px-6 py-2 bg-primaryColor text-white rounded-lg ml-auto"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
        );
          case 2:
            return (
              <>
                {screenWidth < 1024 ? (
                  // Mobile version
                  <div className="flex flex-col gap-6 px-4 pt-6 pb-20">
                    <div className="flex items-center gap-2">
                      <div
                        className="w-10 h-10 rounded-full bg-yellow-300 text-black flex items-center justify-center text-sm font-bold"
                        style={{ fontSize: "16px" }}
                      >
                        01
                      </div>
                      <p className="text-base font-bold pl-2" style={{ fontSize: "20px" }}>Data Nasabah</p>
                    </div>

                    <hr className="border-gray-400" />
            
                    <p className="text-sm text-gray-600" style={{ fontSize: "16px" }}>Silahkan isi data nasabah OCBC Summer Camp 2025.</p>
            
                    <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                      <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold">Nama Depan Nasabah</label>
                        <FormField
                          id="firstName"
                          fieldType="text"
                          placeholder="Masukkan nama depan"
                          value={formData.firstName}
                          onInput={(e) => setFormData({...formData, firstName: e.target.value})}
                        />
                      </div>
            
                      <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold">Nama Belakang Nasabah</label>
                        <FormField
                          id="lastName"
                          fieldType="text"
                          placeholder="Masukkan nama belakang"
                          value={formData.lastName}
                          onInput={(e) => setFormData({...formData, lastName: e.target.value})}
                        />
                      </div>
            
                      <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold">Email Nasabah</label>
                        <FormField
                          id="email"
                          fieldType="text"
                          placeholder="Masukkan email"
                          value={formData.email}
                          onInput={handleEmailChange}
                        />
                        {emailError && (
                            <span className="text-red-500 text-sm">{emailError}</span>
                        )}
                      </div>
            
                      <div className="flex flex-col gap-2">
                        <label className="text-sm font-bold">No. HP Nasabah</label>
                        <FormField
                          id="phone"
                          fieldType="text"
                          placeholder="Masukkan nomor HP"
                          value={formData.phone}
                          onInput={handlePhoneChange}
                        />
                        {phoneError && (
                          <span className="text-red-500 text-sm">{phoneError}</span>
                        )}
                        <span className="text-xs text-gray-500">
                          Pastikan no. HP terhubung dengan WhatsApp
                          <br/> (contoh: 08123456789)
                        </span> 
                      </div>
                    </div>
            
                    <div className="flex justify-between mt-4">
                      <button
                        onClick={() => setStep(step + 1) || emailError !== "" || phoneError !== ""}
                        disabled={!validateStep(step)}
                        className={`px-10 py-2.5 rounded-lg text-sm font-semibold ml-auto ${
                          validateStep(step)
                            ? 'bg-primaryColor text-white'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        }`}
                      >
                        Lanjut
                      </button>
                    </div>
                  </div>
                ) : (
                  // Desktop version
                  <div className="flex flex-col gap-10 pt-10 pl-60 pr-72 mx-auto">
                    <div className="flex flex-col gap-6 pb-6">
                      <p className="text-gray-600">Silahkan isi data nasabah OCBC Summer Camp 2025.</p>
                      
                      <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-4">
                          <label className="w-44 font-bold">Nama Depan Nasabah</label>
                          <div className="flex-1 flex flex-col gap-1">
                            <input
                              type="text"
                              id="firstName"
                              placeholder="Masukkan nama depan"
                              value={formData.firstName}
                              onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                              className="h-14 px-4 border border-gray-300 rounded-full text-black"
                            />
                          </div>
                        </div>
            
                        <div className="flex items-center gap-4">
                          <label className="w-44 font-bold">Nama Belakang<br/>Nasabah</label>
                          <div className="flex-1 flex flex-col gap-1">
                            <input
                              type="text"
                              id="lastName"
                              placeholder="Masukkan nama belakang"
                              value={formData.lastName}
                              onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                              className="h-14 px-4 border border-gray-300 rounded-full text-black"
                            />
                          </div>
                        </div>
            
                        <div className="flex items-center gap-4">
                          <label className="w-44 font-bold">Email Nasabah</label>
                          <div className="flex-1 flex flex-col gap-1">
                            <input
                              type="email"
                              id="email"
                              placeholder="Masukkan email"
                              value={formData.email}
                              onChange={handleEmailChange}
                              className="h-14 px-4 border border-gray-300 rounded-full text-black"
                            />
                            {emailError && (
                              <span className="text-red-500 text-sm">{emailError}</span>
                            )}
                          </div>
                        </div>
                                  
                        <div className="flex items-start gap-4">
                          <label className="w-44 font-bold pt-4">No. Hp Nasabah</label>
                          <div className="flex-1 flex flex-col gap-1">
                            <input
                              type="text"
                              id="phone"
                              placeholder="Masukkan nomor HP"
                              value={formData.phone}
                              onChange={handlePhoneChange}
                              className="h-14 px-4 border border-gray-300 rounded-full text-black"
                            />
                            {phoneError && (
                              <span className="text-red-500 text-sm">{phoneError}</span>
                            )}
                            <span className="text-sm text-gray-500">
                              Pastikan no. HP terhubung dengan WhatsApp (contoh: 08123456789)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
            
                    <div className="flex justify-between pb-18">
                      {step < 6 ? (
                        <button
                          onClick={() => setStep(step + 1)}
                          disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                          className={`px-13 py-3.5 rounded-lg font-semibold ml-auto ${
                            validateStep(step)
                              ? 'bg-primaryColor text-white'
                              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          }`}
                        >
                          Lanjut
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="px-6 py-2 bg-primaryColor text-white rounded-lg ml-auto"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            );            
            case 3:
              return (
                <>
                  {screenWidth < 1024 ? (
                    // Mobile version
                    <div className="flex flex-col gap-6 px-4 pt-6 pb-20">
                      <div className="flex items-center gap-2">
                        <div
                          className="w-10 h-10 rounded-full bg-yellow-300 text-black flex items-center justify-center text-sm font-bold"
                          style={{ fontSize: "16px" }}
                        >
                          02
                        </div>
                        <div className="flex flex-col pl-2" style={{ fontSize: "20px" }}>
                          <p className="text-base font-bold">Pengembalian Materi</p>
                          <p className="text-base font-bold">Dokumentasi Peserta</p>
                        </div>
                      </div>

                      <hr className="border-gray-400" />
              
                      <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                        <p className="text-sm text-gray-500">
                          Nasabah memberi kuasa kepada
                          <span className="font-semibold"> Bank OCBC dan Vendor Penyelenggara untuk memotret; merekam; mengedit; dan menggunakan foto dan video dari Peserta </span> 
                          dengan cara apa pun atau media apa pun, termasuk penggunaan tidak terbatas untuk tujuan publisitas, iklan, dan promosi penjualan; 
                          dalam bentuk tidak terbatas pada media digital dan cetak.
                        </p>
                        
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold">Note: </span>Jika Anda memilih Tidak Setuju, maka peserta tidak akan diikutsertakan dalam seluruh kegiatan dokumentasi selama program
                        </p>
              
                        <div className="flex flex-col text-sm text-black gap-3 mt-2">
                          <div className="flex items-center gap-2">
                            <input
                              type="radio"
                              id="agree"
                              name="documentConsent"
                              value="setuju"
                              className="w-4 h-4 cursor-pointer"
                              style={{ color: "#898989" }}
                              onChange={(e) => setFormData({...formData, documentConsent: e.target.value})}
                              checked={formData.documentConsent === 'setuju'}
                            />
                            <label htmlFor="agree">Setuju</label>
                          </div>
              
                          <div className="flex items-center gap-2">
                            <input
                              type="radio"
                              id="disagree"
                              name="documentConsent"
                              value="tidak setuju"
                              className="w-4 h-4 cursor-pointer"
                              style={{ color: "#898989" }}
                              onChange={(e) => setFormData({...formData, documentConsent: e.target.value})}
                              checked={formData.documentConsent === 'tidak setuju'}
                            />
                            <label htmlFor="disagree">Tidak Setuju</label>
                          </div>
                        </div>
                      </div>
              
                      <div className="flex justify-between mt-4">
                        {step > 1 && (
                          <button
                            onClick={() => setStep(step - 1)}
                            className="px-4 py-2 bg-white border border-primaryColor text-primaryColor rounded-lg text-sm font-semibold"
                          >
                            Sebelumnya
                          </button>
                        )}
                        <button
                          onClick={() => setStep(step + 1)}
                          disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                          className={`px-10 py-2.5 rounded-lg text-sm font-semibold ml-auto ${
                            validateStep(step)
                              ? 'bg-primaryColor text-white'
                              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          }`}
                        >
                          Lanjut
                        </button>
                      </div>
                    </div>
                  ) : (
                    // Desktop version
                    <div className="flex flex-col gap-10 pt-10 px-72 mx-auto">
                      <div className="flex flex-col gap-6">
                        <div className="flex flex-col pb-22 gap-4" style={{ fontSize: "16px" }}>
                          <p className="text-sm text-gray-500">
                            Nasabah memberi kuasa kepada <span className="font-semibold">Bank OCBC dan Vendor Penyelenggara untuk memotret; merekam; mengedit; dan menggunakan foto dan video dari Peserta </span>
                            dengan cara apa pun atau media apa pun, termasuk penggunaan tidak terbatas untuk tujuan publisitas, iklan, dan promosi penjualan; dalam bentuk tidak terbatas pada media digital dan cetak.
                          </p>
                          <p className="text-sm text-gray-500">
                          <span className="font-semibold">Note: </span>Jika Anda memilih Tidak Setuju, maka peserta tidak akan diikutsertakan dalam seluruh kegiatan dokumentasi selama program
                          </p>
                          
                          <div className="flex flex-col text-sm text-black gap-3 mt-4">
                            <div className="flex items-center gap-2">
                              <input
                                type="radio"
                                id="agree"
                                name="documentConsent"
                                value="setuju"
                                className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                onChange={(e) => setFormData({...formData, documentConsent: e.target.value})}
                                checked={formData.documentConsent === 'setuju'}
                              />
                              <label htmlFor="agree">Setuju</label>
                            </div>
              
                            <div className="flex items-center gap-2">
                              <input
                                type="radio"
                                id="disagree"
                                name="documentConsent"
                                value="tidak setuju"
                                className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                onChange={(e) => setFormData({...formData, documentConsent: e.target.value})}
                                checked={formData.documentConsent === 'tidak setuju'}
                              />
                              <label htmlFor="disagree">Tidak Setuju</label>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <div className="flex justify-between pb-18">
                        {step > 1 && (
                          <button
                            onClick={() => setStep(step - 1)}
                            className="px-6 py-2 bg-white font-semibold border border-primaryColor text-primaryColor rounded-lg"
                          >
                            Sebelumnya
                          </button>
                        )}
                        {step < 5 ? (
                          <button
                            onClick={() => setStep(step + 1)}
                            disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                            className={`px-13 py-3.5 rounded-lg font-semibold ml-auto ${
                              validateStep(step)
                                ? 'bg-primaryColor text-white'
                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                          >
                            Lanjut
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="px-6 py-2 bg-primaryColor text-white rounded ml-auto"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              );              
              case 4:
                return (
                  <>
                    {screenWidth < 1024 ? (
                      // Mobile version
                      <div className="flex flex-col gap-6 px-4 pt-6 pb-20">
                      <div className="flex items-center gap-2">
                        <div
                          className="w-10 h-10 rounded-full bg-yellow-300 text-black flex items-center justify-center text-sm font-bold"
                          style={{ fontSize: "16px" }}
                        >
                            03
                          </div>
                          <p className="text-base font-bold" style={{ fontSize: "20px" }}>Konfirmasi Data Peserta</p>
                        </div>

                        <hr className="border-gray-400" />
                
                        <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                          <p className="text-sm text-gray-600">
                            Apakah Anda mendaftarkan program untuk diri Anda sendiri?
                          </p>
                          <p className="text-sm text-gray-600">
                          <span className="font-semibold">Note: </span>Pilih <span className="font-semibold">Ya</span> jika Anda merupakan peserta program. Pilih <span className="font-semibold">Tidak</span> jika peserta yang didaftarkan adalah orang lain (contoh: anak/relasi Anda)
                          </p>
                          
                          <div className="flex flex-col text-sm text-black gap-3 mt-2">
                            <div className="flex items-center gap-2">
                              <input
                                type="radio"
                                id="self"
                                name="selfRegistration"
                                value="ya"
                                className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                onChange={(e) => setFormData({...formData, selfRegistration: e.target.value})}
                                checked={formData.selfRegistration === 'ya'}
                              />
                              <label htmlFor="self">Ya</label>
                            </div>
                            <div className="flex items-center gap-2">
                              <input
                                type="radio"
                                id="other"
                                name="selfRegistration"
                                value="tidak"
                                className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                onChange={(e) => setFormData({...formData, selfRegistration: e.target.value})}
                                checked={formData.selfRegistration === 'tidak'}
                              />
                              <label htmlFor="other">Tidak</label>
                            </div>
                          </div>
                        </div>
                
                        <div className="flex justify-between mt-40">
                          {step > 1 && (
                            <button
                              onClick={() => setStep(step - 1)}
                              className="px-4 py-2 bg-white border border-primaryColor text-primaryColor rounded-lg text-sm font-bold"
                            >
                              Sebelumnya
                            </button>
                          )}
                          <button
                            onClick={() => setStep(step + 1)}
                            disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                            className={`px-10 py-2.5 rounded-lg text-sm font-bold ml-auto ${
                              validateStep(step)
                                ? 'bg-primaryColor text-white'
                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                          >
                            Lanjut
                          </button>
                        </div>
                      </div>
                    ) : (
                      // Desktop version
                      <div className="flex flex-col gap-10 pt-10 px-72 mx-auto">
                        <div className="flex flex-col gap-6">
                        <div className="flex flex-col pb-22 gap-4" style={{ fontSize: "16px"}}>
                            <p className="text-sm text-gray-600">
                              Apakah Anda mendaftarkan program untuk diri Anda sendiri?
                            </p>
                            <p className="text-sm text-gray-600">
                              <span className="font-semibold">Note: </span>Pilih <span className="font-semibold">Ya</span> jika Anda merupakan peserta program. Pilih <span className="font-semibold">Tidak</span> jika peserta yang didaftarkan adalah orang lain (contoh: anak/relasi Anda)
                            </p>
                            
                            <div className="flex flex-col text-sm text-black gap-3 mt-4">
                              <div className="flex items-center gap-2">
                                <input
                                  type="radio"
                                  id="self"
                                  name="selfRegistration"
                                  value="ya"
                                  className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                  onChange={(e) => setFormData({...formData, selfRegistration: e.target.value})}
                                  checked={formData.selfRegistration === 'ya'}
                                />
                                <label htmlFor="self">Ya</label>
                              </div>
                              <div className="flex items-center gap-2">
                                <input
                                  type="radio"
                                  id="other"
                                  name="selfRegistration"
                                  value="tidak"
                                  className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                  onChange={(e) => setFormData({...formData, selfRegistration: e.target.value})}
                                  checked={formData.selfRegistration === 'tidak'}
                                />
                                <label htmlFor="other">Tidak</label>
                              </div>
                            </div>
                          </div>
                        </div>
                
                        <div className="flex justify-between mt-2 pb-18">
                          {step > 1 && (
                            <button
                              onClick={() => setStep(step - 1)}
                              className="px-6 py-2 bg-white font-semibold border border-primaryColor text-primaryColor rounded-lg"
                            >
                              Sebelumnya
                            </button>
                          )}
                          {step < 5 ? (
                            <button
                              onClick={() => setStep(step + 1)}
                              disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                              className={`px-13 py-3.5 rounded-lg font-semibold ml-auto ${
                                validateStep(step)
                                  ? 'bg-primaryColor text-white'
                                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                              }`}
                            >
                              Lanjut
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="px-6 py-2 bg-primaryColor text-white rounded-lg ml-auto"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                );
              case 5:
                return (
                  <>
                    {screenWidth < 1024 ? (
                      // Mobile version
                      <div className="flex flex-col gap-6 px-4 pt-6 pb-12">
                        <div className="flex items-center gap-2 mb-2">
                        <div
                          className="w-10 h-10 rounded-full bg-yellow-300 text-black flex items-center justify-center text-sm font-bold"
                          style={{ fontSize: "16px" }}
                        >
                            04
                          </div>
                          <p className="text-base font-bold" style={{ fontSize: "20px" }}>Data Peserta</p>
                        </div>

                        <hr className="border-gray-400" />

                        <p className="text-sm text-gray-600">Silahkan isi data nasabah OCBC Summer Camp 2025.</p>

                        {formData.selfRegistration === 'ya' ? (
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Nama Depan Peserta</label>
                              <input
                                type="text"
                                value={formData.firstName}
                                disabled
                                className="h-10 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                              />
                            </div>

                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Nama Belakang Peserta</label>
                              <input
                                type="text"
                                value={formData.lastName}
                                disabled
                                className="h-10 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                              />
                            </div>

                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Email Peserta</label>
                              <input
                                type="text"
                                value={formData.email}
                                disabled
                                className="h-10 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                              />
                            </div>

                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">No. HP Peserta</label>
                              <input
                                type="text"
                                value={formData.phone}
                                disabled
                                className="h-10 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Nama Depan Peserta</label>
                              <input
                                type="text"
                                value={formData.firstNamePeserta || ''}
                                onChange={(e) => setFormData({...formData, firstNamePeserta: e.target.value})}
                                placeholder="Masukkan nama depan"
                                className="h-10 w-full px-4 border border-gray-300 rounded-full text-black"
                              />
                            </div>

                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Nama Belakang Peserta</label>
                              <input
                                type="text"
                                value={formData.lastNamePeserta || ''}
                                onChange={(e) => setFormData({...formData, lastNamePeserta: e.target.value})}
                                placeholder="Masukkan nama belakang"
                                className="h-10 w-full px-4 border border-gray-300 rounded-full text-black"
                              />
                            </div>

                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Email Peserta</label>
                              <div className="flex flex-col gap-1">
                                <input
                                  type="text"
                                  value={formData.emailPeserta || ''}
                                  onChange={handleEmailPesertaChange}
                                  placeholder="Masukkan email"
                                  className="h-10 w-full px-4 border border-gray-300 rounded-full text-black"
                                />
                                {emailError && (
                                  <span className="text-red-500 text-sm ml-4">{emailError}</span>
                                )}
                              </div>
                            </div>

                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">No. HP Peserta</label>
                              <div className="flex flex-col gap-1">
                                <input
                                  type="text"
                                  value={formData.phonePeserta || ''}
                                  onChange={(e) => {
                                    const phone = e.target.value;
                                    
                                    if (!validatePhone(phone) && phone !== "") {
                                      setPhoneError("Phone number can only contain numbers");
                                      return;
                                    }
                                    
                                    setPhoneError("");
                                    setFormData({...formData, phonePeserta: phone});
                                  }}
                                  placeholder="Masukkan nomor HP"
                                  className="h-10 w-full px-4 border border-gray-300 rounded-full text-black"
                                />
                                {phoneError && (
                                  <span className="text-red-500 text-sm ml-4">{phoneError}</span>
                                )}
                                <span className="text-xs text-gray-500 ml-4">
                                  Pastikan no. HP terhubung dengan WhatsApp (contoh: 08123456789)
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label className="text-sm font-bold">Jenis Kelamin</label>
                            <select
                              value={formData.gender || ""}
                              onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                              className={`h-10 w-full px-4 border border-gray-300 rounded-full 
                                ${formData.gender ? 'text-black' : 'text-gray-500'}`}
                            >
                              <option value="" disabled selected>Pilih jenis kelamin</option>
                              <option value="L">Laki-laki</option>
                              <option value="P">Perempuan</option>
                            </select>
                          </div>

                          <div className="flex flex-col gap-2">
                            <label className="text-sm font-bold">Tanggal Lahir</label>
                            <input
                              type="date"
                              value={formData.birthDate}
                              onChange={(e) => setFormData({ ...formData, birthDate: e.target.value })}
                              max="2010-06-28"
                              className={`h-10 w-full px-4 border border-gray-300 rounded-full 
                                ${formData.birthDate ? 'text-black' : 'text-gray-400'}`}
                            />
                          </div>

                          <div className="flex flex-col gap-2">
                            <label className="text-sm font-bold">Asal Sekolah</label>
                            <input
                              type="text"
                              value={formData.school}
                              onChange={(e) => setFormData({...formData, school: e.target.value})}
                              placeholder="Masukkan nama sekolah"
                              className="h-10 w-full px-4 border border-gray-300 rounded-full text-black"
                            />
                          </div>

                          <div className="flex flex-col gap-2">
                            <label className="w-48 font-semibold">Tingkat Kelas Saat Ini</label>
                            <select
                              value={formData.grade || ""}
                              onChange={(e) => setFormData({ ...formData, grade: e.target.value })}
                              className={`h-10 w-full px-4 border border-gray-300 rounded-full
                                ${formData.grade ? 'text-black' : 'text-gray-500'}`}
                            >
                              <option value="" disabled>Pilih tingkat kelas</option>
                              <optgroup label="Kelas">
                                {eduLevels
                                  .filter(level => !level.name.includes("Kuliah"))
                                  .map((level) => (
                                    <option key={level.id} value={level.name}>
                                      {`Kelas ${level.name}`}
                                    </option>
                                ))}
                              </optgroup>
                              <optgroup label="Kuliah">
                                {eduLevels
                                  .filter(level => level.name.includes("Kuliah"))
                                  .map((level) => (
                                    <option key={level.id} value={level.name}>
                                      {level.name}
                                    </option>
                                ))}
                              </optgroup>
                            </select>
                          </div>

                          {formData.grade?.includes('Kuliah') && (
                            <div className="flex flex-col gap-2">
                              <label className="text-sm font-bold">Jurusan</label>
                              <input
                                type="text"
                                value={formData.major}
                                onChange={(e) => setFormData({...formData, major: e.target.value})}
                                placeholder="Masukkan jurusan"
                                className="h-10 w-full px-4 border border-gray-300 rounded-full text-black"
                              />
                            </div>
                          )}
                        </div>

                        <div className="flex justify-between mt-12">
                          <button
                            onClick={() => setStep(step - 1)}
                            className="px-4 py-2 bg-white border font-bold border-primaryColor text-primaryColor rounded-lg text-sm"
                          >
                            Sebelumnya
                          </button>
                          <button
                            onClick={() => setStep(step + 1)}
                            disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                            className={`px-10 py-2.5 rounded-lg font-bold text-sm ml-auto ${
                              validateStep(step)
                                ? 'bg-primaryColor text-white'
                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                          >
                            Lanjut
                          </button>
                        </div>
                      </div>
                    ) : (
                      // Desktop version
                      <div className="flex flex-col gap-10 pt-10 pl-60 pr-72 mx-auto">
                        <div className="flex flex-col gap-6 pb-10">
                          <p className="text-gray-600">Silahkan isi data nasabah OCBC Summer Camp 2025.</p>
                          
                          {formData.selfRegistration === 'ya' ? (
                            <div className="flex flex-col gap-6">
                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Nama Depan Peserta</label>
                                <div className="w-[567px] flex-1">
                                  <input
                                    type="text"
                                    value={formData.firstName}
                                    disabled
                                    className="h-14 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                                  />
                                </div>
                              </div>
                
                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Nama Belakang Peserta</label>
                                <div className="w-[567px] flex-1">
                                <input
                                    type="text"
                                    value={formData.lastName}
                                    disabled
                                    className="h-14 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                                  />
                                </div>
                              </div>
                
                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Email Peserta</label>
                                <div className="w-[567px] flex-1">
                                <input
                                    type="text"
                                    value={formData.email}
                                    disabled
                                    className="h-14 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                                  />
                                </div>
                              </div>
                
                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">No. HP Peserta</label>
                                <div className="w-[567px] flex-1">
                                <input
                                    type="text"
                                    value={formData.phone}
                                    disabled
                                    className="h-14 w-full px-4 bg-gray-50 border border-gray-300 rounded-full text-gray-500"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-6">
                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Nama Depan Peserta</label>
                                <div className="w-[567px] flex-1">
                                  <input
                                    type="text"
                                    value={formData.firstNamePeserta || ''}
                                    onChange={(e) => setFormData({...formData, firstNamePeserta: e.target.value})}
                                    placeholder="Masukkan nama depan"
                                    className="h-14 w-full px-4 border border-gray-300 rounded-full text-black"
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Nama Belakang Peserta</label>
                                <div className="w-[567px] flex-1">
                                  <input
                                    type="text"
                                    value={formData.lastNamePeserta || ''}
                                    onChange={(e) => setFormData({...formData, lastNamePeserta: e.target.value})}
                                    placeholder="Masukkan nama belakang"
                                    className="h-14 w-full px-4 border border-gray-300 rounded-full text-black"
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Email Peserta</label>
                                <div className="w-[567px] flex-1 flex flex-col gap-1">
                                  <input
                                    type="text"
                                    value={formData.emailPeserta || ''}
                                    onChange={handleEmailPesertaChange}
                                    placeholder="Masukkan email"
                                    className="h-14 w-full px-4 border border-gray-300 rounded-full text-black"
                                  />
                                  {emailError && (
                                    <span className="text-red-500 text-sm ml-4">{emailError}</span>
                                  )}
                                </div>
                              </div>

                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold -mt-6">No. HP Peserta</label>
                                <div className="w-[567px] flex-1 flex flex-col gap-1">
                                  <input
                                    type="text"
                                    value={formData.phonePeserta || ''}
                                    onChange={(e) => {
                                      const phone = e.target.value;
                                      
                                      if (!validatePhone(phone) && phone !== "") {
                                        setPhoneError("Phone number can only contain numbers");
                                        return;
                                      }
                                      
                                      setPhoneError("");
                                      setFormData({...formData, phonePeserta: phone});
                                    }}
                                    placeholder="Masukkan nomor HP"
                                    className="h-14 w-full px-4 border border-gray-300 rounded-full text-black"
                                  />
                                  {phoneError && (
                                    <span className="text-red-500 text-sm ml-4">{phoneError}</span>
                                  )}
                                  <span className="text-sm text-gray-500 ml-4">
                                    Pastikan no. HP terhubung dengan WhatsApp (contoh: 08123456789)
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                          }
                
                          <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-4">
                              <label className="w-48 font-semibold">Jenis Kelamin</label>
                              <div className="w-[567px] flex-1">
                                <select
                                  value={formData.gender || ""}
                                  onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                                  className={`h-14 w-full px-4 border border-gray-300 rounded-full 
                                    ${formData.gender ? 'text-black' : 'text-gray-500'}`}
                                >
                                  <option value="" disabled>Pilih jenis kelamin</option>
                                  <option value="L">Laki-laki</option>
                                  <option value="P">Perempuan</option>
                                </select>
                              </div>
                            </div>
                
                            <div className="flex items-center gap-4">
                              <label className="w-48 font-semibold">Tanggal Lahir</label>
                              <div className="w-[567px] flex-1">
                                <input
                                  type="date"
                                  value={formData.birthDate}
                                  onChange={(e) => setFormData({ ...formData, birthDate: e.target.value })}
                                  max="2010-06-28"
                                  placeholder="Pilih tanggal lahir"
                                  className={`h-14 w-full px-4 border border-gray-300 rounded-full 
                                    ${formData.birthDate ? 'text-black' : 'text-gray-400'}`}
                                />
                              </div>
                            </div>
                
                            <div className="flex items-center gap-4">
                              <label className="w-48 font-semibold">Asal Sekolah</label>
                              <div className="w-[567px] flex-1">
                                <input
                                  type="text"
                                  list="schools"
                                  value={formData.school}
                                  onChange={(e) => setFormData({...formData, school: e.target.value})}
                                  placeholder="Masukkan nama sekolah"
                                  className="h-14 w-full px-4 border border-gray-300 rounded-full text-black"
                                />
                              </div>
                            </div>
                
                            <div className="flex items-center gap-4">
                              <label className="w-48 font-semibold">Tingkat Kelas Saat Ini</label>
                              <div className="w-[567px] flex-1">
                                <select
                                  value={formData.grade || ""}
                                  onChange={(e) => setFormData({ ...formData, grade: e.target.value })}
                                  className={`h-14 w-full px-4 border border-gray-300 rounded-full
                                    ${formData.grade ? 'text-black' : 'text-gray-500'}`}
                                >
                                  <option value="" disabled>Pilih tingkat kelas</option>
                                  <optgroup label="Kelas">
                                    {eduLevels
                                      .filter(level => !level.name.includes("Kuliah"))
                                      .map((level) => (
                                        <option key={level.id} value={level.name}>
                                          {`Kelas ${level.name}`}
                                        </option>
                                    ))}
                                  </optgroup>
                                  <optgroup label="Kuliah">
                                    {eduLevels
                                      .filter(level => level.name.includes("Kuliah"))
                                      .map((level) => (
                                        <option key={level.id} value={level.name}>
                                          {level.name}
                                        </option>
                                    ))}
                                  </optgroup>
                                </select>
                              </div>
                            </div>

                            {formData.grade?.includes('Kuliah') && (
                              <div className="flex items-center gap-4">
                                <label className="w-48 font-semibold">Jurusan</label>
                                <div className="flex-1">
                                  <input
                                    type="text"
                                    value={formData.major}
                                    onChange={(e) => setFormData({...formData, major: e.target.value})}
                                    placeholder="Masukkan jurusan"
                                    className="h-14 w-full px-4 border border-gray-300 rounded-full text-black"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                
                        <div className="flex justify-between pb-18">
                          <button
                            onClick={() => setStep(step - 1)}
                            className="px-6 py-2 bg-white font-semibold border border-primaryColor text-primaryColor rounded-lg"
                          >
                            Sebelumnya
                          </button>
                            <button
                              onClick={() => setStep(step + 1)}
                                disabled={!validateStep(step) || emailError !== "" || phoneError !== ""}
                                className={`px-13 py-3.5 rounded-lg font-semibold ml-auto ${
                                  validateStep(step)
                                    ? 'bg-primaryColor text-white'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                              >
                              Lanjut
                            </button>
                        </div>
                      </div>
                    )}
                  </>
                );              
                case 6:
                  return (
                    // Mobile version
                    <>
                      {screenWidth < 1024 ? (
                        <div className="flex flex-col gap-6 px-4 pt-6">
                          <h2 className="font-bold pt-5" style={{ fontSize: "20px" }}>Review Form Reservasi</h2>
                          <hr className="mb-px" />
                          <p className="text-gray-600">Silahkan review kembali form reservasi Anda sebelum disubmit.</p>
                          
                          {/* Data Nasabah Section */}
                          <div>
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-base font-semibold text-primaryColor" style={{ fontSize: "20px" }}>Data Nasabah</h2>
                              <button onClick={() => setStep(2)} className="text-sm text-primaryColor hover:text-blue-700">
                                <img src="/edit_button_review.svg" alt="edit" className="w-4 h-4" />
                              </button>
                            </div>
                            <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Nama Nasabah</p>
                                <p className="font-normal">{`${formData.firstName} ${formData.lastName}`}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Email Nasabah</p>
                                <p className="font-normal">{formData.email}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">No. HP Nasabah</p>
                                <p className="font-normal">{formData.phone}</p>
                              </div>
                            </div>
                          </div>

                          <hr className="my-4" />

                          {/* Pengambilan Materi Section */}
                          <div>
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-base font-semibold text-primaryColor" style={{ fontSize: "20px" }}>Pengambilan Materi <br/>Dokumentasi Peserta</h2>
                              <button onClick={() => setStep(3)} className="text-sm text-primaryColor hover:text-blue-700">
                                <img src="/edit_button_review.svg" alt="edit" className="w-4 h-4" />
                              </button>
                            </div>
                            <div className="flex flex-col gap-4">
                              <div className="flex flex-col gap-1">
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      id="agree"
                                      name="documentConsent"
                                      value="setuju"
                                      className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                      checked={formData.documentConsent === 'setuju'}
                                      disabled
                                    />
                                    <label htmlFor="agree">Setuju</label>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      id="disagree"
                                      name="documentConsent"
                                      value="tidak setuju"
                                      className="w-4 h-4 cursor-pointer"
                                style={{ color: "#898989" }}
                                      checked={formData.documentConsent === 'tidak setuju'}
                                      disabled
                                    />
                                    <label htmlFor="disagree">Tidak Setuju</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr className="my-4" />

                          {/* Data Peserta Section */}
                          <div>
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-base font-semibold text-primaryColor" style={{ fontSize: "20px" }}>Data Peserta</h2>
                              <button onClick={() => setStep(5)} className="text-sm text-primaryColor hover:text-blue-700">
                                <img src="/edit_button_review.svg" alt="edit" className="w-4 h-4" />
                              </button>
                            </div>
                            <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Nama Peserta</p>
                                <p className="font-normal">{formData.selfRegistration === 'ya' 
                                    ? `${formData.firstName} ${formData.lastName}`
                                    : `${formData.firstNamePeserta} ${formData.lastNamePeserta}`}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Email Peserta</p>
                                <p className="font-normal">
                                    {formData.selfRegistration === 'ya' 
                                      ? formData.email 
                                      : formData.emailPeserta}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">No. HP Peserta</p>
                                <p className="font-normal">
                                  {formData.selfRegistration === 'ya' 
                                    ? formData.phone 
                                    : formData.phonePeserta}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Jenis Kelamin</p>
                                <p className="font-normal">{formData.gender === 'L' ? 'Laki-laki' : 'Perempuan'}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Tanggal Lahir</p>
                                <p className="font-normal">
                                  {formData.birthDate.split('-').reverse().join('-')}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Asal Sekolah</p>
                                <p className="font-normal">{formData.school}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Tingkat Kelas Saat Ini</p>
                                <p className="font-normal">{formData.grade}</p>
                              </div>
                              {formData.grade?.includes('Semester') && (
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">Jurusan</p>
                                  <p className="font-normal">{formData.major}</p>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Navigation Buttons */}
                          <div className="flex mb-10 pt-10 justify-end">
                            <button 
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                              className={`px-10 py-2.5 bg-primaryColor text-white rounded-lg ${
                                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                              }`}
                            >
                              {isSubmitting ? 'Submit' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      ) : (
                        // Desktop version
                        <div className="flex flex-col gap-8 pt-10 pl-24 pr-8 mx-auto">
                          <div>
                            <h2 className="text-2xl font-bold">Review Form Reservasi</h2>
                            <div className="h-0.5 my-4 bg-gray-200"></div>
                          </div>
                
                          <div className="w-full px-64 mx-auto">
                            <div className="flex flex-col gap-6">
                              <p className="text-gray-600">Silahkan review kembali form reservasi Anda sebelum disubmit.</p>
                              
                              {/* Data Nasabah Section */}
                              <div>
                                <div className="flex justify-between items-center mb-8">
                                  <h2 className="text-xl font-semibold text-primaryColor">Data Nasabah</h2>
                                  <button
                                    onClick={() => setStep(2)}
                                    className="text-sm text-primaryColor hover:text-blue-700"
                                  >
                                    <img 
                                      src="/edit_button_review.svg" 
                                      alt="edit"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>

                                <div className="flex flex-col gap-8">
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Nama Nasabah</p>
                                    <p className="font-normal">{`${formData.firstName} ${formData.lastName}`}</p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Email Nasabah</p>
                                    <p className="font-normal">{`${formData.email}`}</p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">No. HP Nasabah</p>
                                    <p className="font-normal">{`${formData.phone}`}</p>
                                  </div>
                                </div>
                              </div>

                              <hr className="my-4" />
                
                              {/* Pengambilan Materi Section */}
                              <div>
                                <div className="flex justify-between items-center mb-4">
                                  <h2 className="text-xl font-semibold text-primaryColor">Pengambilan Materi Dokumentasi Peserta</h2>
                                  <button
                                    onClick={() => setStep(3)}
                                    className="text-sm text-primaryColor hover:text-blue-700"
                                  >
                                    <img 
                                      src="/edit_button_review.svg" 
                                      alt="edit"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                                <div className="flex flex-col gap-2">
                                  <div className="flex flex-col gap-3 mt-4">
                                    <div className="flex items-center gap-2">
                                      <input
                                        type="radio"
                                        id="agree"
                                        name="documentConsent"
                                        value="setuju"
                                        className="w-4 h-4"
                                        style={{ color: "#898989" }}
                                        checked={formData.documentConsent === 'setuju'}
                                        disabled
                                      />
                                      <label htmlFor="agree">Setuju</label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                      <input
                                        type="radio"
                                        id="disagree"
                                        name="documentConsent"
                                        value="tidak setuju"
                                        className="w-4 h-4"
                                        style={{ color: "#898989" }}
                                        checked={formData.documentConsent === 'tidak setuju'}
                                        disabled
                                      />
                                      <label htmlFor="disagree">Tidak Setuju</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr className="my-4" />
                
                              {/* Data Peserta Section */}
                              <div>
                                <div className="flex justify-between items-center mb-8">
                                  <h2 className="text-xl font-semibold text-primaryColor">Data Peserta</h2>
                                  <button
                                    onClick={() => setStep(5)}
                                    className="text-sm text-primaryColor hover:text-blue-700"
                                  >
                                    <img 
                                      src="/edit_button_review.svg" 
                                      alt="edit"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                                <div className="flex flex-col gap-8">
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Nama Peserta</p>
                                    <p className="font-normal">
                                      {formData.selfRegistration === 'ya' 
                                        ? `${formData.firstName} ${formData.lastName}`
                                        : `${formData.firstNamePeserta} ${formData.lastNamePeserta}`}
                                    </p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Email Peserta</p>
                                    <p className="font-normal">
                                      {formData.selfRegistration === 'ya' 
                                        ? formData.email 
                                        : formData.emailPeserta}
                                    </p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">No. HP Peserta</p>
                                    <p className="font-normal">
                                      {formData.selfRegistration === 'ya' 
                                        ? formData.phone 
                                        : formData.phonePeserta}
                                    </p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Jenis Kelamin</p>
                                    <p className="font-normal">{formData.gender === 'L' ? 'Laki-laki' : 'Perempuan'}</p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Tanggal Lahir</p>
                                      {formData.birthDate.split('-').reverse().join('-')}
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Asal Sekolah/<br/>Universitas</p>
                                    <p className="font-normal">{formData.school}</p>
                                  </div>
                                  <div className="flex flex-row gap-4">
                                    <p className="w-48 font-semibold">Tingkat Kelas Saat Ini</p>
                                    <p className="font-normal">{formData.grade}</p>
                                  </div>
                                  {formData.grade?.includes('Semester') && (
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Jurusan</p>
                                      <p className="font-normal">{formData.major}</p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* Navigation Buttons */}
                              <div className="flex pb-18 justify-end">
                              <button 
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                className={`px-13 py-3.5 bg-primaryColor font-semibold text-white rounded-lg ${
                                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              >
                                {isSubmitting ? 'Submit' : 'Submit'}
                              </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                  case 7:
                    return (
                      <>
                        {screenWidth < 1024 ? (
                        <div className="flex flex-col gap-6 px-4 pt-6">
                          <h2 className="font-bold pt-5" style={{ fontSize: "20px" }}>Menunggu Konfirmasi Registrasi</h2>
                          <hr className="mb-px" />

                          <p className="text-gray-600">
                            Terima kasih telah melakukan pengisian Form Reservasi OCBC Summer Camp 2025.
                            <br/>
                            <br/>
                            OCBC Summer Camp 2025 adalah program khusus nasabah Premier Banking OCBC untuk mendaftarkan anak atau relasi 
                            yang sedang duduk di bangku SMA (atau sederajat) ke dalam Program Summer Camp yang berkolaborasi secara eksklusif dengan 
                            National University of Singapore (NUS).
                            <br/>
                            <br/>
                            Berikut detail reservasi Anda:
                          </p>
                          
                          {/* Data Nasabah Section */}
                          <div>
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-base font-semibold text-primaryColor" style={{ fontSize: "20px" }}>Data Nasabah</h2>
                            </div>
                            <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Nama Nasabah</p>
                                <p className="font-normal">{`${formData.firstName} ${formData.lastName}`}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Email Nasabah</p>
                                <p className="font-normal">{formData.email}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">No. HP Nasabah</p>
                                <p className="font-normal">{formData.phone}</p>
                              </div>
                            </div>
                          </div>

                          <hr className="my-4" />

                          {/* Pengambilan Materi Section */}
                          <div>
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-base font-semibold text-primaryColor" style={{ fontSize: "20px" }}>Pengambilan Materi <br/>Dokumentasi Peserta</h2>
                            </div>
                            <div className="flex flex-col gap-4">
                              <div className="flex flex-col gap-1">
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      id="agree"
                                      name="documentConsent"
                                      value="setuju"
                                      className="w-4 h-4"
                                      style={{ color: "#898989" }}
                                      checked={formData.documentConsent === 'setuju'}
                                      disabled
                                    />
                                    <label htmlFor="agree">Setuju</label>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      id="disagree"
                                      name="documentConsent"
                                      value="tidak setuju"
                                      className="w-4 h-4"
                                      style={{ color: "#898989" }}
                                      checked={formData.documentConsent === 'tidak setuju'}
                                      disabled
                                    />
                                    <label htmlFor="disagree">Tidak Setuju</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr className="my-4" />

                          {/* Data Peserta Section */}
                          <div>
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-base font-semibold text-primaryColor" style={{ fontSize: "20px" }}>Data Peserta</h2>
                            </div>
                            <div className="flex flex-col gap-4" style={{ fontSize: "16px" }}>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Nama Peserta</p>
                                <p className="font-normal">{formData.selfRegistration === 'ya' 
                                    ? `${formData.firstName} ${formData.lastName}`
                                    : `${formData.firstNamePeserta} ${formData.lastNamePeserta}`}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Email Peserta</p>
                                <p className="font-normal">
                                    {formData.selfRegistration === 'ya' 
                                      ? formData.email 
                                      : formData.emailPeserta}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">No. HP Peserta</p>
                                <p className="font-normal">
                                  {formData.selfRegistration === 'ya' 
                                    ? formData.phone 
                                    : formData.phonePeserta}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Jenis Kelamin</p>
                                <p className="font-normal">{formData.gender === 'L' ? 'Laki-laki' : 'Perempuan'}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Tanggal Lahir</p>
                                <p className="font-normal">
                                  {formData.birthDate.split('-').reverse().join('-')}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Asal Sekolah</p>
                                <p className="font-normal">{formData.school}</p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">Tingkat Kelas Saat Ini</p>
                                <p className="font-normal">{formData.grade}</p>
                              </div>
                              {formData.grade?.includes('Semester') && (
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">Jurusan</p>
                                  <p className="font-normal">{formData.major}</p>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Text Section */}
                          <div className="items-left mb-4 text-gray-600">
                            <p>
                              Jika reservasi Anda telah memenuhi seluruh kriteria yang ditetapkan oleh National 
                              University of Singapore (NUS), maka Anda akan menerima Email Konfirmasi Reservasi Berhasil 
                              selambat-lambatnya 3 hari kerja setelah dilakukannya pengisian Form Reservasi. Mohon untuk 
                              tidak melakukan pembukaan produk dan penempatan dana sebelum Anda menerima Email Konfirmasi 
                              Reservasi Berhasil.
                            </p>
                            <br/>
                            <p>
                              Informasi lengkap dapat menghubungi Premier Banking Manager 
                              Anda apabila membutuhkan bantuan atau informasi lebih lanjut.
                            </p>
                            <br/>
                            <p className="mb-12">
                              Hormat kami,
                              <br/>
                              <br/>
                              OCBC
                            </p>
                          </div>
                        </div>
                        ) : (
                          // Desktop version
                          <div className="flex flex-col gap-10 pt-10 px-24 mx-auto">
                            <div>
                              <h2 className="text-2xl font-bold">Menunggu Konfirmasi Registrasi</h2>
                              <div className="h-0.5 my-4 bg-gray-200"></div>
                            </div>

                            <div className="w-full px-64 mx-auto">
                              <div className="flex flex-col gap-6">
                                <p className="text-gray-600">
                                  Terima kasih telah melakukan pengisian Form Reservasi OCBC Summer Camp 2025.
                                  <br/>
                                  <br/>
                                  OCBC Summer Camp 2025 adalah program khusus nasabah Premier Banking OCBC untuk mendaftarkan anak atau relasi 
                                  yang sedang duduk di bangku SMA (atau sederajat) ke dalam Program Summer Camp yang berkolaborasi secara eksklusif dengan 
                                  National University of Singapore (NUS).
                                  <br/>
                                  <br/>
                                  Berikut detail reservasi Anda:
                                </p>
                                
                                {/* Data Nasabah Section */}
                                <div>
                                  <div className="flex justify-between items-center mb-8">
                                    <h2 className="text-xl font-semibold text-primaryColor">Data Nasabah</h2>
                                  </div>

                                  <div className="flex flex-col gap-8">
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Nama Nasabah</p>
                                      <p className="font-normal">{`${formData.firstName} ${formData.lastName}`}</p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Email Nasabah</p>
                                      <p className="font-normal">{`${formData.email}`}</p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">No. HP Nasabah</p>
                                      <p className="font-normal">{`${formData.phone}`}</p>
                                    </div>
                                  </div>
                                </div>

                                <hr className="my-4" />

                                {/* Pengambilan Materi Section */}
                                <div>
                                  <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl font-semibold text-primaryColor">Pengambilan Materi Dokumentasi Peserta</h2>
                                  </div>
                                  <div className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                      <div className="flex flex-col gap-3">
                                        <div className="flex items-center gap-2">
                                          <input
                                            type="radio"
                                            id="agree"
                                            name="documentConsent"
                                            value="setuju"
                                            className="w-4 h-4"
                                            style={{ color: "#898989" }}
                                            checked={formData.documentConsent === 'setuju'}
                                            disabled
                                          />
                                          <label htmlFor="agree">Setuju</label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                          <input
                                            type="radio"
                                            id="disagree"
                                            name="documentConsent"
                                            value="tidak setuju"
                                            className="w-4 h-4"
                                            style={{ color: "#898989" }}
                                            checked={formData.documentConsent === 'tidak setuju'}
                                            disabled
                                          />
                                          <label htmlFor="disagree">Tidak Setuju</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <hr className="my-4" />

                                {/* Data Peserta Section */}
                                <div>
                                  <div className="flex justify-between items-center mb-8">
                                    <h2 className="text-xl font-semibold text-primaryColor">Data Peserta</h2>
                                  </div>
                                  <div className="flex flex-col gap-8">
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Nama Peserta</p>
                                      <p className="font-normal">
                                        {formData.selfRegistration === 'ya' 
                                          ? `${formData.firstName} ${formData.lastName}`
                                          : `${formData.firstNamePeserta} ${formData.lastNamePeserta}`}
                                      </p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Email Peserta</p>
                                      <p className="font-normal">
                                        {formData.selfRegistration === 'ya' 
                                          ? formData.email 
                                          : formData.emailPeserta}
                                      </p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">No. HP Peserta</p>
                                      <p className="font-normal">
                                        {formData.selfRegistration === 'ya' 
                                          ? formData.phone 
                                          : formData.phonePeserta}
                                      </p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Jenis Kelamin</p>
                                      <p className="font-normal">{formData.gender === 'L' ? 'Laki-laki' : 'Perempuan'}</p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Tanggal Lahir</p>
                                        {formData.birthDate.split('-').reverse().join('-')}
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Asal Sekolah/<br/>Universitas</p>
                                      <p className="font-normal">{formData.school}</p>
                                    </div>
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Tingkat Kelas Saat Ini</p>
                                      <p className="font-normal">{formData.grade}</p>
                                    </div>
                                    {formData.grade?.includes('Semester') && (
                                    <div className="flex flex-row gap-4">
                                      <p className="w-48 font-semibold">Jurusan</p>
                                      <p className="font-normal">{formData.major}</p>
                                    </div>
                                  )}
                                  </div>
                                </div>

                                {/* Text Section */}
                                <div className="items-left mb-4 text-gray-600">
                                  <p>
                                    Jika reservasi Anda telah memenuhi seluruh kriteria yang ditetapkan oleh National 
                                    University of Singapore (NUS), maka Anda akan menerima Email Konfirmasi Reservasi Berhasil 
                                    selambat-lambatnya 3 hari kerja setelah dilakukannya pengisian Form Reservasi. Mohon untuk 
                                    tidak melakukan pembukaan produk dan penempatan dana sebelum Anda menerima Email Konfirmasi 
                                    Reservasi Berhasil.
                                  </p>
                                  <br/>
                                  <p>
                                    Informasi lengkap dapat menghubungi Premier Banking Manager 
                                    Anda apabila membutuhkan bantuan atau informasi lebih lanjut.
                                  </p>
                                  <br/>
                                  <p className="mb-10">
                                    Hormat kami,
                                    <br/>
                                    <br/>
                                    OCBC
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
              default:
                return null;
              }
          };
          
  return (
    <div className="min-h-screen flex flex-col">
      <Headerv4 />
      
      <main className="flex-1">
        {renderHero()}
        {screenWidth >= 1024 && step < 6 && renderProgressBar()}
        {renderStep()}
      </main>

      <Footer />
    </div>
  );
}
