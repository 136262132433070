import React from "react";

export default function TestimonyCard({
  testimony,
  photo,
  source,
  name,
  degree,
}) {
  return (
    <div
      className="flex flex-col justify-between items-stretch bg-white shadow-xl rounded-xl"
      style={{ width: "365.17px", height: "292.02px", padding: "28.44px" }}
    >
      <div
        style={{ width: "308.28px", minHeight: "169px", maxHeight: "169px" }}
      >
        <p
          className="text-left font-normal text-sm text-black overflow-ellipsis"
          style={{ lineHeight: "21.7px" }}
          dangerouslySetInnerHTML={{ __html: testimony }}
        />
      </div>
      <div
        className="w-full flex flex-row items-end justify-start"
        style={{
          marginTop: "26px",
          gap: "14.22px",
          minHeight: "44.8px",
          maxHeight: "44.8px",
        }}
      >
        <div
          className={`${
            source === "individual" ? "rounded-full" : "rounded-lg"
          }`}
          style={{ width: "43.33px", height: "43.33px" }}
        >
          <img
            alt="individu"
            src={photo}
            className="w-full h-full object-contain"
          />
        </div>
        <div
          className="w-full h-full flex flex-col items-start justify-center"
          style={{ gap: "1.8px" }}
        >
          <p
            className="text-left font-bold text-sm text-black line-clamp-1 overflow-ellipsis"
            style={{ lineHeight: "21.7px" }}
          >
            {name}
          </p>
          {source === "individual" && (
            <p
              className="text-left font-normal text-sm text-black line-clamp-1 overflow-ellipsis"
              style={{ lineHeight: "20px" }}
            >
              {degree}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
