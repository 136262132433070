import React, { useState, useEffect } from "react";
import Headerv4 from "../components/Header_v4";
import Footer from "../components/Footer";

export default function FormReservasiExpired() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderHero = () => (
    <div 
      className="w-full py-6"
      style={{
        backgroundImage: screenWidth < 1024 
        ? "url('/background_hero_form_reservasi_mobile.svg')"
        : "url('/background_hero_form_reservasi.svg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen">
      <Headerv4 />
      {renderHero()}
      <div className="flex-grow flex items-center justify-center bg-white">
        <div className={ screenWidth < 1024 ? "w-full max-w-xs px-4 pt-20 pb-60" : "w-full max-w-lg px-4 py-25"}>
          <img 
            src={screenWidth < 1024 
              ? "/background_form_reservation_expired_mobile.svg"
              : "/background_form_reservation_expired.svg"
            }
            alt="Form Reservation Expired"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
